@tailwind base;
@tailwind components;
@tailwind utilities;

html { 
  scroll-behavior: smooth; 
}

.fade-in { 
  animation: fadeIn 0.5s; 
}

@keyframes fadeIn { 
  from { opacity: 0; } 
  to { opacity: 1; } 
}

/* It makes the HTML progress bar filling smooth when value change. */
progress::-webkit-progress-value {
  transition: 0.6s width ease-out;
}

@layer base {
  .btn-gradient {
    @apply !bg-gradient !bg-[length:300%_300%] hover:saturate-[1.2] shadow duration-100 !border-0 !border-transparent !bg-transparent animate-shimmer disabled:!bg-none disabled:!bg-gray-500/30  !text-white;
  }
  .btn {
    @apply !capitalize;
  }
}
